import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const FeynPage = () => {
    return (
        <ProjectLayout
            title="Feyn"
            category={ProjectCategory.Game}
            genre="A-RPG"
            date="2016-2017"
            roles="Game designer"
            downloadLink="https://www.youtube.com/redirect?event=video_description&redir_token=QUFFLUhqbmt4Y3pIOGk4b0F1VmtOUGEzb3VmU3Rzdi1KQXxBQ3Jtc0tsbXBBb0xXWmx6eHZyengxZVV5NXFxUTlXUUtscmthV0ZqZ1FfRU5zR1RiTER2WVVhUWg3RTZVWUk5LWhhTFJOX2l0UFB3OXhFQmROQmxWS05TMmtUOHR2WjdTTWwwV0lvakhTVF95TkgwdXItbUdtcw&q=http%3A%2F%2Fwww.mediafire.com%2Ffile%2Fduqvasnmatcitvo%2FFeyn1.1.rar&v=KLGvTup7Znk"
            video="https://www.youtube.com/embed/KLGvTup7Znk"
        >
            <br/>
            <div>
                <p>This game is one of the most ambitious project that I worked on. I spend around a year to work with a
                    group of people for delivering this first chapter.</p>

                <h4>Objectives</h4>

                <ul>
                    <li>Making a light open world action-rpg.</li>
                    <li>Design an original combat system.</li>
                </ul>

                <h5><strong>Combat design:</strong></h5>
                <p>I was in charge of making an original battle system. Because the game is an action-rpg the main focus
                    was made on the action.</p>
                <p>Here are all the actions the character can do:</p>
                <ul>
                    <li>Uses his sword and do a combo of both light or heavy attacks.</li>
                    <ul>
                        <li>The combo is based on the player inputs.</li>
                    </ul>
                    <li>Can parry or roll to dodge the attacks.</li>
                    <li>Uses his magic abilities.</li>
                    <li>A menu where the player can:</li>
                    <ul>
                        <li>Unlock new ability when he levels up (skill tree menu).</li>
                        <li>Assign the ability that he will use during the battle.</li>
                    </ul>
                </ul>

                <p>Of course, because it's an RPG all damages are based on the character and enemies attributes, level
                    and so on.</p>

                <h5><strong>Ability and rage system:</strong></h5>

                <p>
                    Instead of having a system based on the "mana" gauge which decrease when you use abilities, I
                    decided to keep the action live as much as it can which avoid to use constant mana potions.
                </p>
                <p>
                    In reason of the narrative way of the main character's body is full of magic, I
                    turned the original mana feature into a wheel system where the mana increases over time and
                    activate the abilities which enables the player to select and used them.
                </p>
                <p>
                    In Addition to the ability system the character has also a rage gauge which grows while the
                    character takes damage.
                    When the gauge is full the player can activate the rage and has a strength buff until the gauge is
                    empty.
                </p>

                <p>
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="img-fluid" src="/assets/img/projects/feyn/UI_AbilitiesSelector.jpg"
                                 alt="feyn_ability"/>
                        </div>
                        <div className="col-lg-6">
                            <p><strong>Mana behavior</strong></p>
                            <ul>
                                <li>When the battle starts, the mana gauge (blue gauge) is empty and will fill over the
                                    time.
                                </li>
                                <li>If the mana value reaches an ability that will enable the player to select and use
                                    it.
                                </li>
                                <li>If the player uses an ability all the mana that costs to use it will decrease.</li>
                                <li>There is no cooldown after using an ability, if you have enough mana you can use it
                                    again.
                                </li>
                                <li>Regarding the controls, the camera is handled by the system which allows the player
                                    to use the right stick for selecting his abilities.
                                </li>
                            </ul>
                            <p><strong>Rage behavior</strong></p>
                            <ul>
                                <li>When the battle starts, the rage gauge (yellow gauge) remains what it was before,
                                    there is no reset between battle, you can preserve it to the next one.
                                </li>
                                <li>If the rage gauge is full then the wheel is on fire. The player has a good visual
                                    feedback and know when the rage can be used.
                                </li>
                            </ul>
                        </div>
                    </div>
                </p>

                <h4>Additional content</h4>
                <p><EmbedVideo url="https://www.youtube.com/embed/JTlaGvmDEZk"/></p>
                <p><img className="img-fluid" src="/assets/img/projects/feyn/23.png" alt="feyn_03"/></p>
                <p><img className="img-fluid" src="/assets/img/projects/feyn/24.png" alt="feyn_04"/></p>
                <p><img className="img-fluid" src="/assets/img/projects/feyn/22.png" alt="feyn_02"/></p>
            </div>
        </ProjectLayout>
    )
}