import React, {FC, ReactNode, useState} from "react";
import {PortfolioModal} from "./PortfolioModal";

export type PortfolioProjectProps = {
    title: string;
    slug: string;
    img: string;
    filters: string[];
    link?: string;
    modalContent?: ReactNode;
    gameTags?: ReactNode[];
}

export const PortfolioProject: FC<PortfolioProjectProps> = ({
                                                                title,
                                                                slug,
                                                                img,
                                                                filters,
                                                                link,
                                                                modalContent,
                                                                gameTags
                                                            }) => {
    const [display, setDisplay] = useState(false)

    return (
        <>
            <div className={`col-lg-4 col-md-6 portfolio-item ${filters.reduce((c: string, f: string) => `${c} filter-${f}`, '')}`}>
                <div className="portfolio-wrap" onClick={(e) => {
                    if (!link) {
                        e?.preventDefault();
                        setDisplay(!link);
                    }
                }}>
                    <figure>
                        <img src={`assets/img/portfolio/${img}`} className="img-fluid" alt={img}/>
                        <a href={link ? link : '#portfolio'} target={link ? '_blank' : '_self'} onClick={(e) => {
                            if (!link) {
                                e?.preventDefault();
                                setDisplay(!link);
                            }
                        }} className="link-preview" title="Preview" rel="noreferrer"><i className="bx bx-spreadsheet"></i></a>
                    </figure>
                    <div className="portfolio-info">
                        <h4>
                            <a href={link ? link : '#portfolio'} target={link ? '_blank' : '_self'} onClick={(e) => {
                            if (!link) {
                                e?.preventDefault();
                                setDisplay(!link);
                            }
                        }} rel="noreferrer">{title}</a>
                        </h4>
                        <div className="tags">{gameTags}</div>
                    </div>
                </div>
            </div>
            <PortfolioModal show={display} onRequestClose={() => setDisplay(false)}>{modalContent}</PortfolioModal>

            {/*
            <div className={`col-lg-4 col-md-6 portfolio-item filter-${filter}`}>
                <div className="portfolio-wrap">
                    <img src={`assets/img/portfolio/${img}`} className="img-fluid" alt={img}/>
                    <div className="portfolio-links">
                        {link
                            ? <a href={link} target="_blank" rel="noreferrer"><i className="bx bx-plus"></i></a>
                            : <a href="/#portfolio" data-bs-toggle="modal" data-bs-target="#portfolioModal" title={slug}><i className="bx bx-plus"></i></a>
                        }
                    </div>
                </div>
            </div>
            */}
        </>
    )
}