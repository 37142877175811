import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const UmbraFrameworkPage = () => {
    return (
        <ProjectLayout
            title="Umbra Framework"
            description="Umbra Framework is a set of tools which improves the engine experience and add some interesting features that can be used at runtime."
            category={ProjectCategory.Tools}
            genre="Custom engine tool"
            roles="Programmer"
            video="https://www.youtube.com/embed/Be6-4IMY-fY"
            downloadLink="https://github.com/Maxwell21/UT_Framework"
        >
            <br/>
            <div>
                <h4>Objectives</h4>
                <p>
                    Curious about Unreal Engine plugins and tools I decided to dive into the engine and learn how to
                    make
                    custom tools and especially how the visual graph systems (blueprint, behavior tree, ...) were made.
                </p>

                <ul>
                    <li>Learn how to make plugins and engine tools.</li>
                    <li>Override and expose some parts of the engine.</li>
                </ul>

                <h4>Features</h4>
                <ul>
                    <li>State Machine (Create state machine system and start it from any object).</li>
                    <li>Paper 2D Flipbook supports in sequencer editor.</li>
                    <li>Paper 2D Flipbook montage like animation montage for skeletal mesh.</li>
                    <li>An Optimized pooling system which allows you to spawn a lot of actors with high performances.</li>
                    <li>A custom "map range timer" node which can take inputs and transform them like <a
                        href="https://docs.unrealengine.com/4.27/en-US/BlueprintAPI/Math/Float/MapRangeClamped/"
                        target="_blank" rel="noreferrer">Map
                        Range clamped</a> over time.
                    </li>
                    <li>Navigable Widgets with navigation system for UMG. Support both Keyboard and Gamepad.</li>
                </ul>
                <p>
                    <EmbedVideo url="https://www.youtube.com/embed/w3XfJTXJ89w"/>
                </p>
                <p>
                    <img className="img-fluid" src="https://image.ibb.co/ddt0mS/Map_Range_Timer_Overview.gif"
                         alt="Map_Range_Timer_Overview.gif"/>
                </p>
                <p>
                    <img className="img-fluid" src="https://image.ibb.co/fme4H7/Seq_Flipbook_Overview.gif"
                         alt="Seq_Flipbook_Overview.gif"/>
                </p>
            </div>
        </ProjectLayout>
    )
}