import React from "react";

import {ProjectCategory, ProjectEngine, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const Room404Page = () => {
    return (
        <ProjectLayout
            title="Room 404"
            category={ProjectCategory.Game}
            engine={ProjectEngine.UE5}
            genre="Horror"
            roles="Level designer"
            video="https://www.youtube.com/embed/KAH-PJ2K5_8"
            downloadLink="https://www.youtube.com/redirect?event=video_description&redir_token=QUFFLUhqbVlMUVQ2X2pqVXlKZnVydGRPWFEydzliNFE5Z3xBQ3Jtc0tsdTlNUU1wWUZxYjZZSUlLNllPM0JKeGlRaTlyUTBERHBtNU16RktJU2g5Z0pyWHdIQ0JsaExEZDQzUTc1LUpoYzlLV2hUWEV5eEJQQ1ZGTXp3MkJoYlpfa185R1BVU3ZERXVCZ2RPYy01S2hjZkoyOA&q=https%3A%2F%2Fwww.mediafire.com%2Ffile%2Fr1pwkfzrn5u67s9%2FRoom404.zip%2Ffile&v=KAH-PJ2K5_8"
        >
            <br/>
            <p>
                <EmbedVideo url="https://www.youtube.com/embed/XCkLca3QI9k"/>
                <div className="text-center"><em>Another gameplay video focusing only on the other progression
                    approaches.</em></div>
            </p>
            <div>
                <h4>Objectives</h4>
                <p>
                    This project was born after playing some horror game, the goal was to focus on the fear only without
                    any violence.
                    Furthermore, I wanted to focus on the level design aspect and especially the way the player can
                    progress and reach his objectives.
                </p>
                <ul>
                    <li>No weapon, no violence, only fear.</li>
                    <li>Level layout changes during the player progression.</li>
                    <li>Exploration rewards the player in terms of mechanics and storytelling</li>
                    <li>At least two solutions to achieve a progression goal.</li>
                </ul>

                <h4>Context</h4>
                <p>
                    The player is locked in an hotel and wake up after a big noise. Like the protagonist, he doesn't
                    know
                    what happened.
                    The main goal of the game is to leave the hotel.
                </p>


                <h4>Progression</h4>
                <p>
                    Even if the player is locked up and the level is made of corridors and rooms, the idea was to let
                    him explore
                    and achieve his goals by different ways with different tools.
                </p>
                <p>
                    In order to progress from room to room the player must find the appropriate key, entry code or card.
                    But that's not the only way, the player can also use the environment objects for achieving the same
                    goal.
                </p>
                <p>
                    For example, the player can grab a bottle and toss it on an electrical box which is connected to a
                    door code panel that will lead to break both the electrical box and door code.
                    In that way the door will be unlocked.
                </p>
                <div className="row">
                    <div className="col-lg-6">
                        <p>
                            <img className="img-fluid" src="/assets/img/projects/room404/room_07.png" alt="room_07"/>
                            <div className="text-center"><em>Electrical box destroyed.</em></div>
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p>
                            <img className="img-fluid" src="/assets/img/projects/room404/room_06.png" alt="room_06"/>
                            <div className="text-center"><em>Linked door code panel destroyed as well and door
                                unlocked.</em></div>
                        </p>
                    </div>
                </div>

                <h4>Layout</h4>
                <p>
                    Before diving into the level blockout, I decided to draw all the floors' layout in <em>draw.io</em>.
                    It allows me to know that every gameplay elements are used and where but also give me an overview
                    of every narrative elements and interactive gameplay elements.
                </p>
                <p>
                    In that way I know everything about: Which paths are locked, which one are unlockable and if so,
                    how?
                    <ul>
                        <li>Entry code? Does require a key?</li>
                        <li>Is the door linked to a electrical box?</li>
                    </ul>
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/room404/room_01.png" alt="room_01"/>
                    <div className="text-center"><em>Global legend applied for each floor.</em></div>
                </p>

                <p>
                    Early in the game, actually in the first room, the player is locked in and must find a way to leave
                    the room.<br/>
                    That's where he can start interacting with the environment, and if he tries to open the window, he
                    will see that this one is locked which
                    gives him a hint about the fact that the other interactable window could be openable.
                </p>

                <p>
                    <img className="img-fluid" src="/assets/img/projects/room404/room_02.png" alt="room_02"/>
                    <div className="text-center"><em>Floor 04 layout.</em></div>
                </p>

                <p>
                    In addition to the door, the window adds a new layer of exploration and let the player access other
                    room through balcony that can lead to another space where you have a new point of view and allows to
                    see or interact with things that you could not before.
                </p>

                <p>
                    Furthermore, some rooms are only accessible through certain way. Instead of the dedicated regular
                    access (key or entry code)
                    you can go inside only via another room.
                </p>

                <p>
                    <img className="img-fluid" src="/assets/img/projects/room404/room_08.png" alt="room_08"/>
                    <div className="text-center"><em>The player can access to another room if he removes all the planks
                        and fall through the hole.</em></div>
                </p>

                <div className="row">
                    <div className="col-lg-6">
                        <p>
                            <div className="text-center"><em>Floor 03 layout.</em></div>
                            <img className="img-fluid" src="/assets/img/projects/room404/room_03.png" alt="room_03"/>
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p>
                            <div className="text-center"><em>Floor 02 layout.</em></div>
                            <img className="img-fluid" src="/assets/img/projects/room404/room_04.png" alt="room_04"/>
                        </p>
                    </div>
                </div>

                <h4>Flow</h4>

                <p>
                    Because the game doesn't contain any violence, I didn't want it to end up as a walking simulator
                    experience. This is the reason why I put a lot of interactable elements, although, regarding the
                    hotel itself, I made a lot of scripted events that occur in relation of the narration but also
                    depending on the character exploration.
                </p>
                <p>
                    The player manages is own rhythm while playing, however, in order to keep him focused on the game I
                    put a lot of sounds,
                    noises and jump scare that increase sometimes the pacing of the game.
                </p>

                <p>
                    In the same way I designed the level layout, I did a flow graph schema who went over the whole
                    possibilities the player can go through.</p>
                <p>
                    It also ensures that whatever the player choice is, it can still choose another way to play and meanwhile
                    it won't break the game itself.
                </p>
                <p>
                    There are key points where the player needs to find a way to access in order to progress, but most
                    of those
                    situations have at least two ways to solve the problem.
                </p>

                <p>
                    <img className="img-fluid" src="/assets/img/projects/room404/room_05.jpg" alt="room_05"/>
                    <div className="text-center"><em>Global flow graph.</em></div>
                </p>

                <h4>Narration and feedback</h4>
                <p>
                    From the beginning to end the player will find plenty of documents all along the game.
                    Those are the main way for him to understand how to progress but also to know who were in the hotel
                    and what were their relations.
                </p>

                <p>
                    The documents are not only story focused they can also hint the player on how to try new
                    things.<br/>
                    For example, if the player hasn't tried to break an electrical box, he will find a document that
                    explains that someone in the hotel has tossed liquid in the electrical box which unlocked a room
                    with an entry code.
                </p>

                <p>
                    Documents are not the only way to understand the environment and how the hotel works.
                    If the player pay attention to the settings he can find himself new way to solve problems.
                </p>
                <p>
                    If the player follows the wires from the door code, that will lead him towards the
                    electrical box. At the end the player finds the electrical box light shining in the dark room.
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/room404/room_09.png" alt="room_09"/>
                    <div className="text-center"><em>A wires along a wall who leads to an electrical box.</em></div>
                </p>

                <a href="/assets/files/LD_Architecture_Room404.pdf" target="_blank" className="btn btn-primary">
                    Design document <i className="bx bxs-file-archive"></i>
                </a>
            </div>
        </ProjectLayout>
    )
}