import React from "react";

import {Header} from "../../layout/Header";
import {Footer} from "../../layout/Footer";
import {About} from "./sections/About";
import {Portfolio} from "./sections/Portfolio";
import {Resume} from "./sections/Resume";

export const HomePage = () => {
    return (
        <>
            <Header />
            <main id="main">
                <About/>
                <Portfolio/>
                <Resume/>
            </main>
            <Footer />
        </>
    )
}