import profileImg from '../assets/img/profile.jpg'
import {FC} from "react";

export type HeaderProps = {
    activeLink?: string;
}

export const Header: FC<HeaderProps> = ({ activeLink }) => {
  return (
      <header id="header">
          <div className="d-flex flex-column">

              <div className="profile">
                  <img src={profileImg} alt="" className="img-fluid rounded-circle"/>
                      <h1 className="text-light"><a href="/">Axel Clerget</a></h1>
                      <div className="social-links mt-3 text-center">
                          <a href="https://twitter.com/maxwell_n9" className="twitter" target="_blank" rel="noreferrer" ><i className="bx bxl-twitter"></i></a>
                          <a href="https://www.youtube.com/channel/UChY5oc-0YcUHfzwRXTfyyyA?view_as=subscriber" target="_blank"  className="youtube" rel="noreferrer"><i className="bx bxl-youtube"></i></a>
                          <a href="https://www.linkedin.com/in/axel-clerget-38257a142" target="_blank"  className="linkedin" rel="noreferrer"><i className="bx bxl-linkedin"></i></a>
                          <a href="https://github.com/Maxwell21" target="_blank" className="github" rel="noreferrer"><i className="bx bxl-github"></i></a>
                      </div>
              </div>

              <nav id="navbar" className="nav-menu navbar">
                  <ul>
                      <li><a href="#about" className={`nav-link scrollto ${activeLink === 'about' ? 'active' : ''}`}><i className="bx bx-user"></i> <span>About me</span></a></li>
                      <li><a href="#portfolio" className={`nav-link scrollto ${activeLink === 'portfolio' ? 'active' : ''}`}><i className="bx bx-book-content"></i> <span>Portfolio</span></a></li>
                      <li><a href="#resume" className={`nav-link scrollto ${activeLink === 'resume' ? 'active' : ''}`}><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
                      <li><a href="mailto:contact@axelclerget.com" className="nav-link "><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
                  </ul>
              </nav>
          </div>
      </header>
  )
}