import React from "react";

import {ProjectCategory, ProjectEngine, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const KarnagPage = () => {
    return (
        <ProjectLayout
            title="Rise of Karnag"
            description="Game created as a student project. 4 player coop must survive against waves of enemies in a dungeon."
            category={ProjectCategory.Game}
            genre="Hack'n slash"
            date="2011-2012"
            roles="Level designer, Gameplay programmer"
            video="https://www.youtube.com/embed/1HpngPn-NpI"
            engine={ProjectEngine.Unity}
            downloadLink="https://www.youtube.com/watch?v=tCmWRap6Uc4&list=PLTL3j7U_nizGa0vZgPby8A91G7vHUI45-&index=1"
        >
            <br/>
            <div>
                <h4>Objectives</h4>
                <p>This game has been created as a student project with schoolmate. I was in charge of a portion of the programming part in <strong>Unity</strong> with <strong>C#</strong>.</p>
                <ul>
                    <li>Creating a simple and fun cooperative hack'n slash game.</li>
                    <li>Different balanced classes where each one is complementary in order to get a good experience.</li>
                    <li>Express a little bit surviving with stressing moments.</li>
                </ul>

                <h4>Level designer</h4>
                <p>As level designer I was responsible for:</p>
                <ul>
                    <li>Designing the layout and the flow of the dungeon.</li>
                    <li>Using the level art assets for the final result.</li>
                    <li>Placing the player and enemies spawn points.</li>
                </ul>
                <EmbedVideo url="https://www.youtube.com/embed/019NzHQsApE" />
                <br/>

                <h4>Gameplay programmer</h4>
                <p>As gameplay programmer I was responsible for implementing:</p>
                <ul>
                    <li>character abilities and character controls.</li>
                    <li>AI systems and behavior.</li>
                    <li>Wave tool and system.</li>
                </ul>

                <h4>Additional info</h4>
                <p>You can find more work in progress video <a className="btn btn-primary" href="https://www.youtube.com/watch?v=tCmWRap6Uc4" target="_blank" rel="noreferrer">here</a></p>
            </div>
        </ProjectLayout>
    )
}