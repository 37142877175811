import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const UnrealFPVPage = () => {
    return (
        <ProjectLayout
            title="Unreal FPV"
            category={ProjectCategory.Game}
            genre="Drone simulator"
            date="2014-2015"
            roles="Game designer, Gameplay programmer"
            video="https://www.youtube.com/embed/ICnzYPhAzTo"
        >
            <br/>
            <div>
                <h4>Objectives</h4>
                <p>This game has been initiated by <strong>Yannoch</strong>, a 3D artist passionate about FPV drone. I
                    joined the project near the beginning of the production and I worked on different parts with different
                    roles.
                </p>
                <p>
                    Back then, no drone game simulator was existing, we were one of the first tackling this kind of game.A couple of people and companies were
                    interested about the project, so that was quite motivating to work on that game.
                </p>

                <ul>
                    <li>The goal was delivering a true and faithful experience closer to the reality.</li>
                </ul>

                <h4>Game designer</h4>
                <p>As game designer I was responsible for:</p>
                <ul>
                    <li>Designing and prototyping mechanics such as level editor, drone customization.</li>
                    <li>Designing and prototyping the drone fly modes (Stable, assisted, 3D) in order to give the choice to the player.</li>
                    <li>Balancing the drone controls depending on the custom components attached to it.</li>
                    <li>Designing game modes:</li>
                    <ul>
                        <li>Ghost time trial race.</li>
                        <li>Roaming mode where you can join an online session with the ability to fly and see other players.</li>
                    </ul>
                </ul>
                <EmbedVideo url="https://www.youtube.com/embed/l4l8YoxUuEQ"/>
                <br/>

                <h4>Gameplay programmer</h4>
                <p>As gameplay programmer I was responsible for:</p>
                <ul>
                    <li>Implementing the ghost lap system.</li>
                    <li>Implementing in game Level editor where player can save and share their maps.</li>
                    <li>Managing external controllers such as Radio controller.</li>
                    <li>Implementing in game drone customization editor.</li>
                    <li>Refactoring some part of the drone physics in place.</li>
                </ul>
                <EmbedVideo url="https://www.youtube.com/embed/FHKAq0yr7lw"/>
                <br/>

                <h4>Additional info</h4>
                <p>You can find more work in progress video <a className="btn btn-primary"
                                                               href="https://www.youtube.com/watch?v=l4l8YoxUuEQ&list=PLC3BvdCzu25hB5QyQ_-maTcvYdK2K3zWU"
                                                               target="_blank" rel="noreferrer">here</a></p>
            </div>
        </ProjectLayout>
    )
}