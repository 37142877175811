import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";
import {EmbedVideo} from "../../../components/EmbedVideo";

export const CompilationPage = () => {
    return (
        <ProjectLayout
            title="Compilation"
            category={ProjectCategory.Game}
            genre=""
            roles="Game design, Level design, Gameplay programmer"
            video="https://www.youtube.com/embed/p-7y3TKU6Qc"
        >

            <br/>
            <div>
                <h3>Braver</h3>
                <h4>Objectives</h4>
                <p>
                    Braver is a short linear experience (~10 minutes) focused on the action inspired by the souls game
                    genre.
                </p>

                <h4>Game designer</h4>
                <p>As game designer I was responsible for:</p>
                <ul>
                    <li>Designing the regular AI and final boss behavior.</li>
                </ul>

                <h4>Level designer</h4>
                <p>As level designer I was responsible for:</p>
                <ul>
                    <li>Polishing the level with effects, level art.</li>
                    <li>Placing destructible and spawnable objects.</li>
                </ul>

            </div>

            <hr/>

            <div>
                <p>
                    <EmbedVideo url="https://www.youtube.com/embed/mEv9uC6Bdes"/>
                </p>
                <h3>Showreel</h3>
                <h4>Objectives</h4>
                <p>A linear cinematographic action game experience.</p>

                <h4>Level designer</h4>
                <p>As level designer I was responsible for:</p>
                <ul>
                    <li>Designing the whole level layout with God of War 2018 level design as reference.</li>
                    <li>Scripting all cutscenes events and battle encounters.</li>
                </ul>
                <p>
                    I designed the layout with more verticality with the ability to climb wall. <br/>
                    I also designed the layout for the boss battles and all regular battle encounters. <br/>
                    I was in charge of the level flow as well. <br/>
                </p>
            </div>

            <hr/>

            <div>
                <p>
                    <EmbedVideo url="https://www.youtube.com/embed/RirU0F3PKSU"/>
                </p>
                <p>
                    <EmbedVideo url="https://www.youtube.com/embed/XQuv-28MptU"/>
                </p>
                <p>
                    <EmbedVideo url="https://www.youtube.com/embed/W17uk-zGrIU"/>
                </p>

                <h3>Prime</h3>
                <h4>Objectives</h4>
                <p>
                    Prototype where I tried few character and camera controls with a fast pace action with both gun and
                    sword.
                </p>

                <h4>Game designer</h4>
                <p>As game designer I was responsible for:</p>
                <ul>
                    <li>Designing and prototyping the controls when roaming or fighting.</li>
                    <li>Designing the crafting system where the player can craft his own bullets for the gun. The
                        bullets can be elementary and apply damage over time to the target.
                    </li>
                </ul>
            </div>

        </ProjectLayout>
    )
}