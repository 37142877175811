import {FC, PropsWithChildren} from "react";
import {Button, Modal} from "react-bootstrap";

export const PortfolioModal: FC<PropsWithChildren<{show: boolean, onRequestClose: () => void}>> = ({show, children, onRequestClose }) => {
    return (
        <Modal show={show} fullscreen={true} style={{zIndex: 999999}}>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button type="button"  onClick={onRequestClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )

    /*
        <div className="modal fade" id="portfolioModal" aria-hidden="true" tabIndex={-1} style={{zIndex: 999999}}>
            <div className="modal-dialog modal-lg modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    */
}