import React, {FC, PropsWithChildren} from "react";
import {EmbedVideo} from "../components/EmbedVideo";

export type ProjectLayoutProps = {
    title: string;
    description?: string;
    category: ProjectCategory;
    genre?: string;
    date?: string;
    video?: string;
    downloadLink?: string;
    downloadLinkTitle?: string;
    roles: string;
    engine?: ProjectEngine;
} & PropsWithChildren

export enum ProjectCategory {
    Game = 'Game',
    Tools = 'Tools',
    Prototype = 'Prototype',
}

export enum ProjectEngine {
    UE4 = 'Unreal Engine 4',
    UE5 = 'Unreal Engine 5',
    Portal2 = 'Portal 2 - Puzzle maker',
    Unity = 'Unity',
}

export const ProjectLayout: FC<ProjectLayoutProps> = ({
                                                          title,
                                                          category = ProjectCategory.Game,
                                                          genre,
                                                          date,
                                                          video,
                                                          description,
                                                          roles,
                                                          downloadLink,
                                                          downloadLinkTitle = 'Download',
                                                          engine = ProjectEngine.UE4,
                                                          children
                                                      }) => {
    return (
        <>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{title}</h2>
                        <ol>
                            <li data-bs-dismiss="modal" aria-label="Close"><a href="#portfolio">Portfolio</a></li>
                            <li>{title}</li>
                        </ol>
                    </div>
                </div>
            </section>

            <section id="portfolio-details" className="portfolio-details">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            {video ? <EmbedVideo url={video}/> : null}
                            {children}
                        </div>

                        <div className="col-lg-4">
                            <div className="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Category</strong>: {category}</li>
                                    {genre ? (<li><strong>Genre</strong>: {genre}</li>) : null}
                                    <li><strong>Roles</strong>: {roles}</li>
                                    <li><strong>Engine</strong>: {engine}</li>
                                    {date ? <li><strong>Project date</strong>: {date}</li> : null}
                                    {downloadLink ?
                                        <li>
                                            <a className="btn btn-primary" href={downloadLink} target="_blank"
                                               rel="noreferrer">{downloadLinkTitle} <i className="bx bx-file"></i></a>
                                        </li> : undefined}
                                </ul>
                            </div>
                            {description ? (
                                <div className="portfolio-description">
                                    <h2>Description</h2>
                                    <p>{description}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}