import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";

export const UmbraDialoguePage = () => {
    return (
        <ProjectLayout
            title="Umbra Dialogue"
            description="Umbra Dialogue is a dialogue system plugin developed for unreal engine."
            category={ProjectCategory.Tools}
            genre="Custom engine tool"
            roles="Programmer"
            video="https://www.youtube.com/embed/XzPNCqqWv4M"
        >
            <br/>
            <div>
                <h4>Objectives</h4>
                <p>
                    Tired of using excel sheets and unreal data table system I decided to build my own system in unreal engine.
                    This plugin was born also due to a necessity for a project.
                </p>
                <p>
                    It was built with the ability to be extended in order to fit any other project that would require a dialogue system.
                </p>

                <h4>Features</h4>
                <p>Below all the features that I have developed for the plugin.</p>
                <ul>
                    <li>Custom dialogue asset with graph editor to facilitate the process of making dialogue.</li>
                    <li>Fragment (regular dialogue text) with voice and animation support on actors.</li>
                    <li>Ability to setup choice easily with also animation and voice support.</li>
                    <li>Ability to put delays between nodes.</li>
                    <li>Handle condition for branching depending on the situation.</li>
                    <li>Can trigger world events.</li>
                    <li>Can be used in sequence or trigger sequence as well.</li>
                </ul>
            </div>
        </ProjectLayout>
    )
}