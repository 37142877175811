import React from "react";

import {ProjectCategory, ProjectEngine, ProjectLayout} from "../../../layout/ProjectLayout";

export const PortalPuzzlePage = () => {
    return (
        <ProjectLayout
            title="Portal 2 - Puzzle"
            category={ProjectCategory.Game}
            engine={ProjectEngine.Portal2}
            genre="Puzzle"
            roles="Level designer"
            video="https://www.youtube.com/embed/ReCNDTs3ocU"
        >
            <br/>

            <h4>Context</h4>
            <p>
                This project has been made with the built in "puzzle maker" provided by Portal 2 game. It was my first
                complete project with that tool that enabled me to get used to it.
            </p>
            <p>
                Using this tool was also a good challenge because it contains many constraints such as the restricted area
                you can build your level in, but also in terms of gameplay features that you can use to deliver the
                puzzle.
            </p>

            <div>
                <h4>Objectives</h4>
                <p>
                    Rather than having a classic Portal puzzle where you can shoot your portals everywhere, I decided to
                    explore another way to make that experience by restricting that feature and let the player use the
                    tools.
                </p>

                <h4>Layout - 1st room</h4>
                <p>
                    Just after his first steps in the level, the player is going to see
                    <ul>
                        <li>The goal: the exit gate.</li>
                        <li>The Obstacles: the bridges deactivated.</li>
                    </ul>
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_A_01.png"
                        alt="Puzzle_A_01"/></p>
                <p>
                    The switch next to the bridge activates only a part of the bridge that leads to the exit door.
                    <br/>
                    If the player follows the wires connected to the second part of the bridge, he knows that he has to
                    go up there in the room with the big window, and so, go through the red laser that block him.
                </p>
                <div className="row">
                    <div className="col-lg-6">
                        <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_A_02.png"
                                alt="Puzzle_A_02"/></p>
                    </div>
                    <div className="col-lg-6">
                        <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_A_03.png"
                                alt="Puzzle_A_03"/></p>
                    </div>
                </div>

                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_B_01.png"
                        alt="Puzzle_B_01"/></p>
                <p>
                    For deactivating the laser the player must grab and put a cube in the right slot.
                    Instead of putting fizzler and portable wall everywhere, and because I want to restrict the gun,
                    the mood of the puzzle change into a platformer game by jumping from specific portal to the moving platform
                    to the slot.
                </p>

                <h4>Layout - The central room</h4>
                <p>
                    When the player reaches the room with the window, he will find two buttons that he has to enable for
                    activating the bridge, nevertheless, the room contains only one cube and the player can't stay on the button so
                    he has to figure out that a cube generator is off and need to be activated.
                    Furthermore the room is fully protected and no object can in and out.
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_C_02.png"
                         alt="Puzzle_C_02"/>
                    <em>
                        <ul>
                            <li>Two cubes to put on each button (1).</li>
                            <li>Deactivated cube generator (2).</li>
                        </ul>
                    </em>
                </p>

                <h4>Layout - Main puzzle</h4>
                <p>
                    The deactivated cube generator is connected to a laser receiver, so, the player understands
                    instantaneously what he has to do by bringing the light to the receiver.
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_D_01.png"
                        alt="Puzzle_D_01"/></p>
                <p>
                    So here comes the main puzzle of the level. The corridor next to the central
                    room is followed by three rooms.
                </p>

                <h5>The corridor</h5>
                <p>
                    First and foremost, there is a switch with a timer in the corridor which controls the flow of the
                    other rooms. There is also the laser receiver connected to the deactivated cube generator in
                    the central room.
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_D_02.png"
                        alt="Puzzle_D_02"/></p>

                <h5>Room A</h5>
                <p>
                    This room contains the laser generator, it's where all starts.
                    By default the laser is blocked by an angled panel, however, when the player presses the corridor's switch
                    the angled panel is retracting and allows the laser to go in a laser relay.
                    Furthermore, it opens another angled panel in the same room, but it doesn't block anything and point
                    in the barrier.
                </p>
                <p>
                    The room contains also a reflective cube generator, two speed gel generator and a cube slot. The
                    player needs to bring the cube to the room C room in order to continue the puzzle. However, the room
                    is protected by a barrier and if the cube hits the barrier it will be destroyed.
                </p>
                <p>
                    So he has to figure out to bring it.
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_E_Soluce.gif"
                         alt="Puzzle_E_Soluce.gif"/>
                    <div className="text-center"><em>Room A - Solution</em></div>
                </p>

                <h5>Room B</h5>
                <p>
                    The room B contains only a sphere that the player has to bring to the room C.
                    In this room the switch enable and disable some barrier that can destroy the sphere. <br/>
                    The player encounters the first "enemy" as a turret and must take cover behind elements to survive.
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_F_01.png"
                        alt="Puzzle_F_01"/>
                </p>
                <p>
                    <em>
                        This room is underneath the corridor and the room B and C. One of the constraint that I had to
                        deal with due to editor width constraint.</em>
                </p>
                <h5>Room C</h5>
                <p>
                    This room is the conclusion of the puzzle, you need to bring every element from the other
                    rooms (the reflective cube and the sphere). In this room there is a reflective cube, a sphere slot,
                    a deactivated moving platform, a reflective cube and a laser relay connected to the room A.
                </p>
                <p>
                    When the corridor's switch is activated the laser from the room A goes to the relay and go through
                    the wall to the relay in the room C.
                </p>
                <p>
                    From there, the reflective cube and the sphere got from the room A and B need to be brought through a
                    hole in the wall from the corridor to the room C, because the access to the room C is protected by a
                    barrier.
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_G_01.png"
                        alt="Puzzle_G_01"/></p>
                <p>
                    This room is less constrain than the other and as the first room you are allowed to use
                    portals on specific places.
                </p>
                <p>
                    By putting one of the reflective cube on the bloc along the wall and the other on the moving
                    platform (with the right angle) you can reflect the laser through the wall and trigger the laser
                    receiver in the corridor.
                </p>
                <p>
                    In order to achieve this, the player must put the sphere in the sphere slot which activates the
                    moving platform.
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_G_02.png"
                        alt="Puzzle_G_02"/>
                    <div className="text-center"><em>Puzzle - Laser solution.</em></div>
                </p>

                <h4>Layout - Conclusion</h4>
                <p>
                    With the cube generator activated, the player can grab and put the missing cube on the pressure
                    button, and with that being said goes back where the bridge is (there is a shortcut from the central
                    room), activate the other part of the bridge and leave the level.
                </p>
                <p><img className="img-fluid" src="/assets/img/projects/portal_puzzle/Puzzle_Conclusion.png"
                        alt="Puzzle_Conclusion"/></p>

            </div>
        </ProjectLayout>
    )
}