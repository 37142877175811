
export const Footer = () => {
  return (
      <footer id="footer">
          <div className="container">
              <div className="copyright">
                  &copy; Copyright <strong><a href="mailto:contact@axelclerget.com">Axel Clerget</a></strong>.
              </div>
              <div className="credits">
                  Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
              </div>
          </div>
      </footer>
  )
}