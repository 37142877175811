import React, {FC} from "react";

export const EmbedVideo: FC<{ url: string }> = ({url}) => {
    return (
        <div className="responsive-video">
            <iframe width="560" height="315" src={url}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
            </iframe>
        </div>
    )
}