import React from "react";

import {ProjectCategory, ProjectLayout} from "../../../layout/ProjectLayout";

export const SeraphChroniclePage = () => {
    return (
        <ProjectLayout
            title="Seraph's chronicle"
            category={ProjectCategory.Game}
            genre="J-RPG"
            roles="Game designer, Level designer"
            video="https://www.youtube.com/embed/PpNrzwIoy_o"
        >
            <br/>
            <div>
                <p>After the reveal of "Octopath Traveler" from Square-Enix, I realized that it was possible to revive
                    and improve the old turned based J-RPG.</p>
                <p>As a big fan of Japanese game and J-RPG I always loved their narrative approach and their top-notch
                    battle system so in the end I decided to do this project.</p>

                <h4>Objectives</h4>

                <ul>
                    <li>Design a linear layout story driven with lots of narrative events and situations.</li>
                    <li>Design a dynamic and original turn based battle system.</li>
                </ul>

                <h4>Game designer</h4>
                <h5><strong>Battle design:</strong></h5>
                <p>In order to have something dynamic I decided to get back to the <a
                    href="https://finalfantasy.fandom.com/wiki/Battle_system#Active_Time_Battle" target="_blank" rel="noreferrer">active
                    time battle system (ATB)</a> from the old Final Fantasy where the player is in realtime engaged to
                    his choice.</p>
                <p>But rather than just taking that system I also wanted to add new things on top of that, so I ended up
                    using a blend of C-RPG and J-RPG.</p>
                <p>I chose to take the "action points" system from the C-RPG games where each action that you want to do
                    costs a specific amount of action point (AP).</p>
                <p>As a result of this system, the player is more engaged and focused on the strategy as well as the
                    decision he has to take during a battle.</p>
                <p>Note: <em>The cost will depend on the power of the action, for instance a simple attack will cost
                    less than a double attack action.</em></p>
                <p>In the end, every action has a cost, if the character has no enough action point he won't be able to
                    perform any action except the magic ones if he has enough magic points.</p>
                <ul>
                    <li>Regular attack or abilities without magic consumes action points.</li>
                    <li>Magic abilities consume mana point.</li>
                    <li>Defend action will have a cost of zero and preserve the AP of the current character turns.</li>
                    <li>Each time the ATB gauge is full the player will recover a portion of AP.</li>
                </ul>
                <p><img className="img-fluid" src="/assets/img/projects/seraph/seraph_03.png" alt="seraph_03"/></p>

                <p>Abilities depend on the gear equipped. Each gear has a set of abilities and each abilities can be
                    learned and mastered by specific characters.</p>
                <p><img className="img-fluid" src="/assets/img/projects/seraph/seraph_01.png" alt="seraph_01"/></p>

                <p>On top of that, each action executed can trigger (based on the lucky attribute of the current
                    character) a booster where the player has a timed mini-game phase and will result as a damage
                    multiplier.</p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/seraph/seraph_02.png" alt="seraph_02"/>
                    <div className="text-center"><em>Boost mini-game triggered.</em></div>
                </p>

                <h5><strong>Battle events:</strong></h5>
                <p>Early in my design concept of the battle system I wanted a battle event system for two reasons.</p>
                <ul>
                    <li>I wanted to be able to blend narrative and battle depending on some battle conditions.</li>
                </ul>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/seraph/seraph_05.png" alt="seraph_05"/>
                    <div className="text-center"><em>Dialogue triggered by a battle event.</em></div>
                </p>
                <ul>
                    <li>I wanted the battle system to be able to react to the weather system and can add effects such as wet
                        status when
                        it's raining.
                    </li>
                    <li>This mechanic can change radically a battle situation, for instance, in the gameplay video it's
                        raining, which applies the wet effect on the robot which leads to increase thunder magic damage.
                    </li>
                </ul>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/seraph/seraph_06.png" alt="seraph_06"/>
                    <div className="text-center"><em>Weather event that impacts the battle.</em></div>
                </p>

                <h5><strong>Misc:</strong></h5>
                <p>
                    I worked also on several things such as
                    <ul>
                        <li>The 3C for both exploration and battle system.</li>
                        <li>Balancing and designing character attributes and abilities.</li>
                        <li>Designing enemies action patterns.</li>
                        <br/>
                    </ul>
                </p>

                <h4>Level designer</h4>
                <p>The intentions when I designed the layout was to have a chain of events and situations where you
                    control a character then another.
                </p>
                <p>Furthermore, I asked myself about how could I improve an old J-RPG
                    level nowadays? I ended up by adding more life in the environment like a ship that takes off while
                    you're exploring or a mini-game in order to break the repetitiveness.
                </p>
                <p>The level is linear and the flow depends on the character that you're controlling. However, there are
                    secret places hidden by the camera that reward the player if he's a little bit curious.</p>
                <p>The pace was designed like this:</p>
                <ul>
                    <li>Crescendo with Rave.</li>
                    <ul>
                        <li>At the beginning the player doesn't have many enemies in a battle encounter in order to let
                            him familiarize with the system itself.
                        </li>
                    </ul>
                    <li>Decrescendo with Gavok.</li>
                    <li>Then a steady increase with Rave again until he meets Gavok for the final boss.</li>
                </ul>
                <p>The music was also designed specifically like that and change depending on the player situation. The FMOD system
                has been used and allows the music to evolve when the player progresses throughout the level.</p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/seraph/seraph_07.png" alt="seraph_07"/>
                    <div className="text-center"><em>Rave in one of the many cutscenes.</em></div>
                </p>

                <p>I mentioned a little bit the weather system in the game design , but that system has been also used
                    in the level design aspect. The weather feature is a dynamic system that can be set for each
                    map and run automatically or by scripting (like the battle against the boss).
                    It enables the level to be more modern and alive.
                </p>

                <p>
                    In the middle of the level the player will encounter a mini game phase where Rave has to cover
                    behind barrels while a ship is shooting towards him.
                </p>
                <p>
                    This moment breaks the loop of repetitiveness (Exploration <i
                    className="bx bx-arrow-to-right"></i> Battle <i
                    className="bx bx-arrow-to-right"></i> Cutscene/Event <i className="bx bx-refresh"></i>) and
                    give a fresh experience to the player.
                </p>
                <p>
                    <img className="img-fluid" src="/assets/img/projects/seraph/seraph_08.png" alt="seraph_08"/>
                    <div className="text-center"><em>Mid-level mini-game.</em></div>
                </p>

                <p>
                    One of the other moment that rewards the player's curiosity happen when Rave found a code and
                    later when you're controlling Gavok you're facing a door that requires the code.
                </p>
                <div className="row">
                    <div className="col-lg-6">
                        <p><img className="img-fluid" src="/assets/img/projects/seraph/seraph_10.png" alt="seraph_10"/>
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p><img className="img-fluid" src="/assets/img/projects/seraph/seraph_09.png" alt="seraph_09"/>
                        </p>
                    </div>
                </div>

            </div>
        </ProjectLayout>
    )
}